$primario1:#d90429;
$primario2:#ef233c;
$primario3:#edf2f4;
$primario4:#cdcdce;
$primario5:#f1f1f9;
$primario6:#2b2d4286;
$primario7:#6c1000;
$botones:#800028;
$botones2:#970131;
$blanco:#fff;
$negro:#000;
$negro2:#333;
$diasbled1:#ee7388;
$bordes-input:#cdcdce;
$bordes-input__active:#a3a3a3;
$fondo2:#fffbfa;
$fondo1:#fdfbef;
$fondo3:#fffbfa;
