
@use 'variables' as v;

@use 'mixins' as m;


html{
    font-size:62.5%;
    box-sizing:border-box;
   
     
}
*,*:before, *:after {
    box-sizing: inherit;
}

body{
  
    font-family: 'Poppins', sans-serif;
    padding-top:8rem;
    font-weight:300;
 
   
}
h1,h2,h3,h4,h5,h6{
    letter-spacing: 1.5px;
    line-height: normal;
   
    
}
p,ul,li,a,input,textarea,legend{
    line-height: 150%;
    font-size:4.2vw;
    margin:0;
    @include m.mobil {

        font-size: 1.8rem;
        line-height: 30px;
        line-height: 30px;
    }
}


h1{
    
    font-size:6.4vw;
    font-weight: 300;
    letter-spacing: 1.5px;
    margin-bottom: 12%;
    
    @include m.mobil {
        font-size:3rem;
        margin-bottom: 5rem;
    }
    @include m.tablet {
        font-size:3.5rem;
    }
    
}
h2{
    font-size:5.8vw;
    @include m.mobil {
        font-size:2.6rem;
    }
  
}
h3,h4{
    font-size:5vw;
    @include m.mobil {
        font-size:2.4rem;
    }
}
li{
    list-style: none;
}
ul{
    padding:0;
}
a{
    text-decoration: none;
    &:hover{
        cursor: pointer;
    }
}



textarea{
    resize: none;
}
::placeholder{
    color:v.$bordes-input
}
label,::placeholder{
    font-size:3.8vw;
    @include m.mobil {
        font-size: 1.4rem;
    }
   
}
.boton-enviar{
    position:relative;
    z-index:100;
    display:block;
    outline: none;
    border:0;
    background-color: v.$primario6;
    color:v.$blanco;
    border-radius:15rem;
    width:80%;
}
input,textarea{
    cursor:pointer;
}

img{
    max-width:100%;
    width:100%;
    height:auto;
}
.seccion__contenedores{
    
    display:flex;
    flex-direction: column;
    padding:5%;
    max-width:160rem;
    margin:auto;
    margin-top:5%;
        
    @include m.tablet {

       @include m.grid2(2);
      
       
        h2{
            grid-column:1/3;
        }
            
    }
    @include m.desktop{
        @include m.grid2(3);
        h2{
            grid-column:1/4;
        }
    }


    &--grid2{
        display:flex;
        flex-direction: column;
        padding:5%;
        max-width:160rem;
        margin:auto;
      
            
        @include m.tablet {
    
           @include m.grid2(2);
            margin-bottom:5%;
            h2{
                grid-column:1/3;
                
            }
                
        }
    }

   
   
    &--trabajos{

        max-width:160rem;
        border:1px solid v.$primario4;
        border-radius: 1.5rem;
        margin:5% 0;
        background-image:url(/build/img/principal/tela5.png);
        background-size: 50%;
        background-position:center;
        background-repeat:no-repeat;
        @include m.tablet {
            margin:5% 2%;
        }
    
    
        &--titulo{

            display:flex;
            justify-content: space-between;
            align-items: center;
            padding:1%;
            @include m.tablet {
                align-items: flex-start;
            }

            h3, p{

                border:1px solid v.$primario4;
                border-radius: 1.5rem;
                padding:0;
                padding:1% 2%;
                margin:1%;
                background-color: v.$primario3;
                @include m.mobil {
                    font-size: 1.8rem;
                }
              
            }
            h3{
                @include m.tablet {
                
                }
                @include m.fullDesktop {
                    font-size: 2.4rem;
                }
            }
            p{
                background-color: v.$botones2;
               
                text-align: center;

                @include m.mobil {
                    width:10rem;
                 
                }
                @include m.tablet {
                 
                    text-align: center;
                
                }
            
                &:hover{
                    background-color: v.$primario7;
                }
            
                a{
                    color:v.$primario3;
                    font-size:3.6vw;
                    @include m.mobil {
                        font-size:1.6rem;
                    }
                }
               
             
            }
       
        }
        &--texto{
            padding:5% 3%;
        }
       

    }
    &--activosweb{
            
        animation: colores 1s;
    
    }
    &--activosapp{
    
        animation: colores 1s;
    
    }
    &--activosmarketing{

        animation: colores 1s;
    
    }
}
@keyframes colores{

    from{
        opacity:0;
       
    }
    to{
        opacity:1;
      
    }
}

//cookies
.cookies-div__bloqueo {
    position: fixed;
    width: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.290);
    z-index: 10000;
    user-select: none;
    margin-top:-8rem;
    animation: colores 0.3s ;

}

.cookies-div__principal {
    position: absolute;
    width: 95%;
    left: 0;
    right: 0;
    margin-left:2.5%;
    background: #fff;
    text-align: center;
    margin-top: 10%;
    max-width: 60rem;
    margin: 10% auto;
}



.cookies-div__imagen {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:5%;

}

.cookies-imagen__cookie {
    width: 25%;
    flex: 3;
    margin-top: -18%;
}

.cookies-imagen__logo {
    width: 7%;
    position: absolute;
    left: 3%;
    top: 8%;
}

.cookies-contenedor__texto {

    margin-top: 15%;
    padding: 0 0rem;

}

.cookies-contenedor__parrafo1 {
    font-size: 3.6vw;
    text-align: center;
    padding: 5%;
    margin-top: 6%;
    margin-bottom:1rem;
    @include m.mobil{
        font-size:1.2rem;
    }
}

.cookies-contenedor__parrafo2 {
    width: 80%;
    margin-left: 10%;
    text-align: left;
    padding-bottom:1.5%;
    font-size:3.4vw;
    line-height: 15px;
    @include m.mobil{
        font-size:1.2rem;
    }

}

.cookies-contenedor__parrafo3 {
    font-size:3.8vw;
    padding: 2%;
    text-align: center;
    margin-top: 3rem;
    @include m.mobil{
        font-size:1.2rem;
    }
}

.cookies-contenedor__boton-aceptar,
.cookies-contenedor__boton-cancelar {
    background-color: v.$diasbled1;
    color: #fff;
    padding: 0.2rem 1.5rem;
    border: 1px solid v.$diasbled1;
    border-radius: 15px;
    margin-top: 0.5rem;
    font-size:1.4rem;

}

.cookies-contenedor__boton-aceptar {
    margin-right: 2%;

}

.cookies-contenedor__boton-cancelar {
    margin-left: 2%;
    background-color: rgb(33, 33, 33);
    border: 1px solid rgb(37, 37, 37);
}

.cookies-div__widget-svg {
    width: 3rem;
    height: 3rem;
    display: flex;
    position: fixed;
    bottom: 0;
    margin: 0.5rem;
    z-index: 10001;
    transform: scale(1.05)

    
}

.cookies-div__retirar-consentimiento {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: red;
}


@media (min-width:440px) {
    .cookies-contenedor__parrafo1 {

        padding: 0;
        font-size: 1.6rem;

    }
}

@media (min-width:600px) {

    .cookies-contenedor__parrafo1 {

        padding: 0;

    }

    .cookies-contenedor__texto {

        margin-top: 5%;
    }
}



@media (min-width:1170px) {

    .cookies-imagen__cookie {
        width: 20%;
        flex: 3;
        margin-top: -14%;
    }
}

/*Aviso de cookies*/

.cookies-aviso__h2 {
    color: rgb(72, 72, 238);
}

.body-cookies__aviso {
    padding: 3rem;
    max-width: 90rem;
    margin: auto;

}

.cookies-aviso__enlace {
    text-align: center;
    font-size: 2rem;

}

.cookies-aviso__enlace a {
    color: rgb(255, 191, 0);
    text-decoration: none;
}
