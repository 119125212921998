
@use '../base/variables' as v;
@use '../base/mixins' as m;
.encabezado{
    width:100%;
    height:8rem;
    display:flex;
    justify-content: baseline;
    position:fixed;
    top:0;
    z-index:5000;
    background-color: v.$primario3;
    transition:margin-top 0.5s ease-out;
   
}
.encabezado__logo{
    display: flex;
    justify-content: center;
    align-items: center;
    width:14%;
    img{
        width:3.5rem;
        @include m.miniMobil {
            width:4rem;
        }
        @include m.mobil{
            width:5rem;
        }
        @include m.tablet {
            width:6rem;
        }
    }
}
.encabezado__texto{
    width:70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    :nth-child(1){
        font-size:2.1rem;
        color:v.$primario6;
    }
    @include m.miniMobil {
        width:63%;
    }
    @include m.mobil {
        width:72%;
        :nth-child(1){
            //font-size:3rem;
        }
    }
  
}

.encabezado__boton{

   display:none;
}
aside{
    display:none;
}
.alerta{
    padding:0 5%;
    font-size:6vw;
    margin:10% 0 10%;
    @include m.mobil {
        font-size:2.4rem;
    }
}

.mensajes{

    h1{
        text-align: center;
        font-size:8vw;
        margin:10% 0 10%;
        padding:0 5%;
        @include m.mobil {
            font-size:3.4rem;
            
        }
        @include m.tablet {
            margin:5rem 0 5rem;
        }
    }

    &__contenido{

        font-size: 6.4vw;
        text-align:center;
        margin-top:15rem;
        @include m.mobil {
            font-size:3rem;
        }
    }
    &__enlace{
        a{
            display:block;
            color:v.$botones2;
            text-align: center;
            font-size:5.8vw;
            border-bottom:4px solid v.$primario3;
            padding:0 5%;
            @include m.mobil {
                font-size:1.8rem;
            }
        }
        
    }

}


