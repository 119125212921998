@mixin miniMobil {
    @media only screen and (min-width:280px){
        @content;
    }
}
@mixin miniMobilPlus{
    @media only screen and (min-width:380px){
        @content;
    }
}

@mixin mobil {
    @media only screen and (min-width:440px){
        @content;
    }
}
@mixin mobilPlus{
    @media only screen and (min-width:568px){
        @content;
    }
}
@mixin mobilPlus600{
    @media only screen and (min-width:600px){
        @content;
    }
}
@mixin tablet {
    @media only screen and (min-width:768px){
        @content;
    }
}
@mixin tabletPlus {
    @media only screen and (min-width:900px){
        @content;
    }
}
@mixin desktop {
    @media only screen and (min-width:1024px){
        @content;
    }
}
@mixin desktopGallery {
    @media only screen and (min-width:1250px){
        @content;
    }
}
@mixin fullDesktop {
    @media only screen and (min-width:1600px){
        @content;
    }
}
@mixin fullDesktop1800 {
    @media only screen and (min-width:1800px){
        @content;
    }
}

@mixin grid ($primera,$segunda){
    display:grid;
    grid-template-columns:$primera $segunda;
    
}
@mixin grid2 ($primera){
    display:grid;
    grid-template-columns:repeat($primera, 1fr);
    
}
